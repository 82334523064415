var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.close,
      "size": "md",
      "is-centered": ""
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "mx": "16px",
      "py": "16px",
      "rounded": "8px"
    }
  }, [_c('c-modal-header', {
    attrs: {
      "m": "auto",
      "pb": "0px"
    }
  }, [_c('CImage', {
    attrs: {
      "size": "150px",
      "object-fit": "cover",
      "src": _vm.imageIllustration,
      "alt": "Image Confirmation"
    }
  })], 1), _c('c-modal-body', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700",
      "text-align": "center",
      "color": "#008C81"
    }
  }, [_vm._v(" Apa kamu yakin ingin mengganti program? ")]), _c('c-modal-footer', {
    attrs: {
      "d": "flex",
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "rounded": "1000px",
      "variant-color": "red",
      "font-size": "14px",
      "font-weight": "500",
      "d": "flex",
      "gap": "8px",
      "w": "100%"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Batal "), _c('inline-svg', {
    attrs: {
      "src": _vm.iconCircleClose,
      "height": "19",
      "width": "19",
      "fill": "white"
    }
  })], 1), _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "mr": "3",
      "rounded": "1000px",
      "font-size": "14px",
      "font-weight": "500",
      "d": "flex",
      "gap": "8px",
      "w": "100%"
    },
    on: {
      "click": _vm.handleConfirm
    }
  }, [_vm._v(" Konfirmasi "), _c('inline-svg', {
    attrs: {
      "src": _vm.iconCircleCheck,
      "height": "19",
      "width": "19",
      "fill": "white"
    }
  })], 1)], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }