<template>
  <c-drawer
    :is-open="isOpen"
    placement="bottom"
    :on-close="close"
    :initial-focus-ref="()=>$refs.inputInsideModal"
  >
    <c-drawer-overlay />
    <c-drawer-content rounded-top="12px">
      <c-drawer-close-button />
      <c-drawer-header
        m="auto"
        pb="0px"
      >
        <CImage
          size="110px"
          object-fit="cover"
          :src="iconCoupon"
          alt="Segun Adebayo"
        />
      </c-drawer-header>

      <c-drawer-body
        text-align="justify"
        font-size="14px"
        font-weight="500"
        color="#3E3E3E"
      >
        Kamu dapat menggunakan kode kupon untuk memotong harga pesananmu. Kode kupon dapat kamu temukan di halaman promosi ataupun secara eksklusif didapatkan melalui promosi khusus yang diadakan oleh Dietela.
      </c-drawer-body>

      <c-drawer-footer>
        <c-button
          font-size="14px"
          font-weight="500"
          variant-color="primary"
          size="lg"
          rounded="1000px"
          w="100%"
          @click="close"
        >
          Kembali
        </c-button>
      </c-drawer-footer>
    </c-drawer-content>
  </c-drawer>
</template>

<script>
import iconCoupon from '@/assets/images/icon-1-raw.png'
import iconCircleQuestionmark from '@/assets/icons/icon-circle-questionmark.svg'

export default {
  name: 'ModalCouponLearnMore',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iconCoupon,
      iconCircleQuestionmark,
    }
  },
  methods: {
    close() {
      this.$emit('set-is-open', false)
    },
  },
}
</script>