var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-drawer', {
    attrs: {
      "is-open": _vm.isOpen,
      "placement": "bottom",
      "on-close": _vm.close,
      "initial-focus-ref": function initialFocusRef() {
        return _vm.$refs.inputInsideModal;
      }
    }
  }, [_c('c-drawer-overlay'), _c('c-drawer-content', {
    attrs: {
      "rounded-top": "12px"
    }
  }, [_c('c-drawer-close-button'), _c('c-drawer-header', {
    attrs: {
      "m": "auto",
      "pb": "0px"
    }
  }, [_c('CImage', {
    attrs: {
      "size": "110px",
      "object-fit": "cover",
      "src": _vm.iconCoupon,
      "alt": "Segun Adebayo"
    }
  })], 1), _c('c-drawer-body', {
    attrs: {
      "text-align": "justify",
      "font-size": "14px",
      "font-weight": "500",
      "color": "#3E3E3E"
    }
  }, [_vm._v(" Kamu dapat menggunakan kode kupon untuk memotong harga pesananmu. Kode kupon dapat kamu temukan di halaman promosi ataupun secara eksklusif didapatkan melalui promosi khusus yang diadakan oleh Dietela. ")]), _c('c-drawer-footer', [_c('c-button', {
    attrs: {
      "font-size": "14px",
      "font-weight": "500",
      "variant-color": "primary",
      "size": "lg",
      "rounded": "1000px",
      "w": "100%"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Kembali ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }